.App {
  text-align: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-MainLogo {
  height: 15vmin;
}

.App-logoL2R {
  height: 20vmin;
  pointer-events: none;
}

.App-logoR2L {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-MainLogo {
    animation: zoom-in-zoom-out infinite 3s linear;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logoL2R {
    animation: Logo-spinL2R infinite 5s ease-in-out;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logoR2L {
    animation: Logo-spinR2L infinite 5s ease-in-out;
  }
}
.App-header {
  min-height: 21vmin;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logos {
  display: flex;
  justify-content: space-evenly;
  padding-top: 50px;
}

.App-link {
  color: #f1a20d;
}

@keyframes Logo-spinL2R {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes Logo-spinR2L {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

.carousel-container {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-overlay {
  position: absolute;
  background: rgba(255, 255, 255, 0.95);
  padding: 20px;
  border-radius: 10px;
  max-width: 80%; 
  max-height: 80%;
  overflow: auto;
  text-align: center;
}
p.legend {
  display: none;
}
h1 {
  font-size: 4vw; /* Change this line */
}
.carousel-status {
  display: none;
}